'use client'
import leftImage from '@/images/truck1.png'
import rightImage from '@/images/truck2.png'
import classNames from "classnames";
import styles from "./styles.module.scss";

import {Logo} from "@/images/Logo";
import Image from "next/image";

interface IErrorScreen {
  type: '404' | 'crash' | 'denied'
  title: string
  description?: string
  buttonText: string
  handler: () => void
}

export const ErrorScreen = (
  {
    type,
    description,
    title,
    buttonText,
    handler,
  }: IErrorScreen) => {

  return (
    <div
      className={classNames(
        styles.wrapper,
      )}
    >
      <Logo
        className={classNames(
          styles.logo,
        )}
      />
      <h1
        className={classNames(
          styles.title,
        )}
      >
        {title}
      </h1>
      <p
        className={classNames(
          styles.description,
        )}
      >
        {description}
      </p>
      <button
        className={classNames(
          styles.btn,
        )}
        onClick={handler}
      >
        {buttonText}
      </button>
      <Image
        className={classNames(
          styles.leftImg,
        )}
        src={leftImage}
        alt={'img'}
      />
      <Image
        className={classNames(
          styles.rightImg,
        )}
        src={rightImage}
        alt={'img'}
      />
    </div>
  )
}
